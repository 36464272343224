import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _8db641f6 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _39d01c66 = () => interopDefault(import('../pages/documents.vue' /* webpackChunkName: "pages/documents" */))
const _984f25a6 = () => interopDefault(import('../pages/documents/_area/index.vue' /* webpackChunkName: "pages/documents/_area/index" */))
const _f1f6bc72 = () => interopDefault(import('../pages/messages.vue' /* webpackChunkName: "pages/messages" */))
const _320c770a = () => interopDefault(import('../pages/messages/index.vue' /* webpackChunkName: "pages/messages/index" */))
const _3736b4f4 = () => interopDefault(import('../pages/messages/create.vue' /* webpackChunkName: "pages/messages/create" */))
const _4d5f1284 = () => interopDefault(import('../pages/messages/inbox.vue' /* webpackChunkName: "pages/messages/inbox" */))
const _6f5e8d90 = () => interopDefault(import('../pages/messages/sent.vue' /* webpackChunkName: "pages/messages/sent" */))
const _1994437e = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _0d9898fe = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _67dacc47 = () => interopDefault(import('../pages/profile/settings/index.vue' /* webpackChunkName: "pages/profile/settings/index" */))
const _215b043f = () => interopDefault(import('../pages/requests.vue' /* webpackChunkName: "pages/requests" */))
const _227d1f82 = () => interopDefault(import('../pages/requests/index.vue' /* webpackChunkName: "pages/requests/index" */))
const _92928c5a = () => interopDefault(import('../pages/requests/new/index.vue' /* webpackChunkName: "pages/requests/new/index" */))
const _ec1051aa = () => interopDefault(import('../pages/requests/submitted.vue' /* webpackChunkName: "pages/requests/submitted" */))
const _782444cc = () => interopDefault(import('../pages/requests/new/_type.vue' /* webpackChunkName: "pages/requests/new/_type" */))
const _7241170d = () => interopDefault(import('../pages/auth/impersonate.vue' /* webpackChunkName: "pages/auth/impersonate" */))
const _19af0606 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _820fa9b4 = () => interopDefault(import('../pages/auth/logout.vue' /* webpackChunkName: "pages/auth/logout" */))
const _200b4e98 = () => interopDefault(import('../pages/auth/registration/index.vue' /* webpackChunkName: "pages/auth/registration/index" */))
const _1d11e80a = () => interopDefault(import('../pages/auth/t2t.vue' /* webpackChunkName: "pages/auth/t2t" */))
const _b45a8f1a = () => interopDefault(import('../pages/auth/registration/update.vue' /* webpackChunkName: "pages/auth/registration/update" */))
const _79e3b023 = () => interopDefault(import('../pages/auth/registration/verify.vue' /* webpackChunkName: "pages/auth/registration/verify" */))
const _8d35cc8c = () => interopDefault(import('../pages/auth/reset/password/index.vue' /* webpackChunkName: "pages/auth/reset/password/index" */))
const _d7fdb8b2 = () => interopDefault(import('../pages/auth/reset/user.vue' /* webpackChunkName: "pages/auth/reset/user" */))
const _35c14132 = () => interopDefault(import('../pages/auth/reset/username.vue' /* webpackChunkName: "pages/auth/reset/username" */))
const _04d24a06 = () => interopDefault(import('../pages/auth/sso/login.vue' /* webpackChunkName: "pages/auth/sso/login" */))
const _3b061ed6 = () => interopDefault(import('../pages/auth/sso/verify.vue' /* webpackChunkName: "pages/auth/sso/verify" */))
const _5a7806f4 = () => interopDefault(import('../pages/profile/settings/delete.vue' /* webpackChunkName: "pages/profile/settings/delete" */))
const _165f7a11 = () => interopDefault(import('../pages/profile/settings/email.vue' /* webpackChunkName: "pages/profile/settings/email" */))
const _80745694 = () => interopDefault(import('../pages/profile/settings/password.vue' /* webpackChunkName: "pages/profile/settings/password" */))
const _0bc34941 = () => interopDefault(import('../pages/auth/reset/password/verify.vue' /* webpackChunkName: "pages/auth/reset/password/verify" */))
const _1912f167 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _27a5919e = () => interopDefault(import('../pages/documents/detail/_id/index.vue' /* webpackChunkName: "pages/documents/detail/_id/index" */))
const _64698b12 = () => interopDefault(import('../pages/messages/detail/_id/index.vue' /* webpackChunkName: "pages/messages/detail/_id/index" */))
const _672716ff = () => interopDefault(import('../pages/requests/detail/_id/index.vue' /* webpackChunkName: "pages/requests/detail/_id/index" */))
const _692840f4 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _8db641f6,
    name: "account___de"
  }, {
    path: "/documents",
    component: _39d01c66,
    name: "documents___de",
    children: [{
      path: ":area",
      component: _984f25a6,
      name: "documents-area___de"
    }]
  }, {
    path: "/messages",
    component: _f1f6bc72,
    children: [{
      path: "",
      component: _320c770a,
      name: "messages___de"
    }, {
      path: "create",
      component: _3736b4f4,
      name: "messages-create___de"
    }, {
      path: "inbox",
      component: _4d5f1284,
      name: "messages-inbox___de"
    }, {
      path: "sent",
      component: _6f5e8d90,
      name: "messages-sent___de"
    }]
  }, {
    path: "/profile",
    component: _1994437e,
    children: [{
      path: "",
      component: _0d9898fe,
      name: "profile___de"
    }, {
      path: "settings",
      component: _67dacc47,
      name: "profile-settings___de"
    }]
  }, {
    path: "/requests",
    component: _215b043f,
    children: [{
      path: "",
      component: _227d1f82,
      name: "requests___de"
    }, {
      path: "new",
      component: _92928c5a,
      name: "requests-new___de"
    }, {
      path: "submitted",
      component: _ec1051aa,
      name: "requests-submitted___de"
    }, {
      path: "new/:type",
      component: _782444cc,
      name: "requests-new-type___de"
    }]
  }, {
    path: "/auth/impersonate",
    component: _7241170d,
    name: "auth-impersonate___de"
  }, {
    path: "/auth/login",
    component: _19af0606,
    name: "auth-login___de"
  }, {
    path: "/auth/logout",
    component: _820fa9b4,
    name: "auth-logout___de"
  }, {
    path: "/auth/registration",
    component: _200b4e98,
    name: "auth-registration___de"
  }, {
    path: "/auth/t2t",
    component: _1d11e80a,
    name: "auth-t2t___de"
  }, {
    path: "/auth/registration/update",
    component: _b45a8f1a,
    name: "auth-registration-update___de"
  }, {
    path: "/auth/registration/verify",
    component: _79e3b023,
    name: "auth-registration-verify___de"
  }, {
    path: "/auth/reset/password",
    component: _8d35cc8c,
    name: "auth-reset-password___de"
  }, {
    path: "/auth/reset/user",
    component: _d7fdb8b2,
    name: "auth-reset-user___de"
  }, {
    path: "/auth/reset/username",
    component: _35c14132,
    name: "auth-reset-username___de"
  }, {
    path: "/auth/sso/login",
    component: _04d24a06,
    name: "auth-sso-login___de"
  }, {
    path: "/auth/sso/verify",
    component: _3b061ed6,
    name: "auth-sso-verify___de"
  }, {
    path: "/profile/settings/delete",
    component: _5a7806f4,
    name: "profile-settings-delete___de"
  }, {
    path: "/profile/settings/email",
    component: _165f7a11,
    name: "profile-settings-email___de"
  }, {
    path: "/profile/settings/password",
    component: _80745694,
    name: "profile-settings-password___de"
  }, {
    path: "/auth/reset/password/verify",
    component: _0bc34941,
    name: "auth-reset-password-verify___de"
  }, {
    path: "/",
    component: _1912f167,
    name: "index___de"
  }, {
    path: "/documents/detail/:id",
    component: _27a5919e,
    name: "documents-detail-id___de"
  }, {
    path: "/messages/detail/:id",
    component: _64698b12,
    name: "messages-detail-id___de"
  }, {
    path: "/requests/detail/:id",
    component: _672716ff,
    name: "requests-detail-id___de"
  }, {
    path: "/requests/new/:type?",
    component: _782444cc,
    name: "requests-new-type___de"
  }, {
    path: "/*",
    component: _692840f4,
    name: "all___de"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
